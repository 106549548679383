import HelperService from '@/services/HelperService';
import axios from '@/libs/axios';

const baseUrl = '/admin/kiosk-site';

export default {
  getSites(filters) {
    const queryString = HelperService.buildSearchQueryString(filters);
    return axios.get(`${baseUrl}${queryString}`);
  },
  getSite(id) {
    return axios.get(`${baseUrl}/${id}`)
  },
  createSite(site) {
    return axios.post(`${baseUrl}`, site)
  },
  updateSite(id, site) {
    return axios.put(`${baseUrl}/${id}`, site)
  },
  deleteSite(id) {
    return axios.delete(`${baseUrl}/${id}`)
  },
  updateApplicationDetails(id, siteAppDetails) {
    return axios.put(`${baseUrl}/${id}/electron-fields`, siteAppDetails)
  },
  async uploadKioskImage(id, image) {
    const formData = new FormData()
    formData.append('file', image)
    const { data } = await axios.put(`${baseUrl}/${id}/upload-image`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return data
  },
}
