<template>
  <div>
    <b-form-group>
      <p class="mt-1">The MDM Name must match up to the apps package name that you wish to target.</p>
      <label class="mt-1" for="mdm-name-in">MDM Name *</label>
      <validation-provider v-slot="validationContext" name="MDM Name" :rules="{ required: true }">
        <b-form-input id="mdm-name-in"
                      v-model="site.mdm_name"
                      class="d-inline-block"
                      :state="getValidationState(validationContext)"
                      placeholder="MDM Name"
        />

        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
      </validation-provider>
    </b-form-group>

    <b-form-group>
      <label for="mdm-name-in">Dashboard Name *</label>
      <validation-provider v-slot="validationContext" name="Dashboard Name" :rules="{ required: true }">
        <b-form-input id="dashboard-name-in"
                      v-model="site.name"
                      class="d-inline-block"
                      :state="getValidationState(validationContext)"
                      placeholder="Dashboard Name"
        />

        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
      </validation-provider>
    </b-form-group>

    <b-form-group>
      <label for="url-in">URL e.g. https://Website.com * </label>
      <validation-provider v-slot="validationContext" name="url" :rules="{ required: true, url }">
        <b-form-input id="url"
                      v-model="site.url"
                      class="d-inline-block"
                      :state="getValidationState(validationContext)"
                      placeholder="URL"
        />
        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
      </validation-provider>
    </b-form-group>

    <div class="mt-2 mb-1">
      <b-btn variant="primary" :disabled="isDisabled" class="float-right" @click="createSite">Add site</b-btn>
      <b-btn class="float-left" @click="$emit('close')">Cancel</b-btn>
    </div>

  </div>
</template>

<script>
import KioskSiteService from '@/services/KioskSiteService';
import helperService from '@/services/HelperService';
import { url } from '@core/utils/validations/validations';

export default {
  name: 'CreateKioskSite',
  data() {
    return {
      site: {
        mdm_name: '',
        url: '',
        name: '',
      },
    }
  },
  computed: {
    isDisabled() {
      return this.site.mdm_name === '' || this.site.url === '' || this.site.name === ''
    },
    url() {
      return url
    },
  },
  methods: {
    async createSite() {
      try {
        const newSite = await KioskSiteService.createSite(this.site)
        helperService.showNotfySuccess(this.$toast, 'Kiosk Site Created Successfully')
        this.$emit('addToTable', newSite.data); // send site back to main page to be appended
      } catch (err) {
        helperService.showNotfyErr(this.$toast, err, 'Could not add your account, please refresh and try again')
      }
    },
    getValidationState({
                         dirty,
                         validated,
                         valid = null,
                       }) {
      return dirty || validated ? valid : null
    },
  },
}
</script>

<style scoped>
</style>
