<style lang="scss">

</style>
<!--eslint-disable-->
<template>
  <div class="Kiosk-sites-page">
    <b-card class="" title="Kiosk Sites">
      <b-btn :to="{ name: 'kiosk-sites'}"
             variant="success"
             class="mr-2 float-right" style="margin-top: -10px;"
             @click="$bvModal.show('modal-create-kiosk-site');"

      >
        Add Kiosk Site
      </b-btn>
      <b-card-text>View currently enabled sites on the Kiosk application</b-card-text>


    </b-card>

    <b-card no-body class="mb-0">
      <div class="row p-2 justify-content-start">

        <!-- Search -->
        <b-col cols="6">
          <b-input-group>
            <b-form-input
              v-model="filters.search"
              @keyup.enter="getMoreSites(1)"
              class="d-inline-block" placeholder="Search by MDM name..."
            />
            <b-input-group-append>
              <b-button @click="getMoreSites(1)">Search</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </div>

      <section>
        <b-table
          class="data-table"
          hover
          :items="sites"
          :fields="headers"
          :current-page="currentPage"
          :per-page="0"
        >
          <template #cell(mdm_name)="data">
            <router-link :to="{ name: 'kiosk-site-view', params: { id: data.item.id, site: data.item }}">
              {{ data.item.mdm_name }}
            </router-link>
          </template>

          <template #cell(urls)="data">
            <div v-if="data.item.urls">
              {{ data.item.urls.length }}
            </div>
            <div v-else>
              0
            </div>
          </template>

          <template #cell(url_blacklist)="data">
            <div v-if="data.item.url_blacklist">
              {{ data.item.url_blacklist.length}}
            </div>
            <div v-else>
              0
            </div>
          </template>

          <template #cell(resource_whitelist)="data">
            <div v-if="data.item.resource_whitelist">
              {{ data.item.resource_whitelist.length}}
            </div>
            <div v-else>
              0
            </div>
          </template>

          <template #cell(javascript)="data">
            <div v-if="data.item.javascript">
              Yes
            </div>
            <div v-else>
              None
            </div>
          </template>

          <template #cell(css_rules)="data">
            <div v-if="data.item.css_rules">
              Yes
            </div>
            <div v-else>
              None
            </div>
          </template>

          <template #cell(actions)="data">
            <b-dropdown class="m-0" variant="link" right toggle-class="text-decoration-none" no-caret>
              <template #button-content>
                <i class="fas fa-ellipsis-v"></i>
              </template>
              <b-dropdown-item-button class="full-nested-btn" @click="showDeleteModal(data.item, 'modal-delete-kiosk-site')">
                Delete
              </b-dropdown-item-button>
              <b-dropdown-item-button class="full-nested-btn" @click="$router.push({name:'kiosk-site-view', params: { id: data.item.id, site: data.item }}); ">
                View Kiosk Site
              </b-dropdown-item-button>
            </b-dropdown>
          </template>

        </b-table>
        <b-row>
          <b-col md="6" class="my-1" v-if="total_count > filters.page_size">
            <b-pagination @change="getMoreSites" :total-rows="total_count" :per-page="filters.page_size" v-model="currentPage" class="my-0"/>
          </b-col>
          <b-col class="ml-2 mt-1" v-if="total_count === 0">
            <p >No results</p>
          </b-col>
        </b-row>
      </section>
    </b-card>

    <b-modal id="modal-delete-kiosk-site" title="Delete URL" hide-footer>
      <delete-modal
        v-if="selectedSite"
        @close="closeModals()" @delete="deleteSite"
        :subtitle="selectedSite.name"
        title="Are you sure you wish to make this change to the kiosk site?"></delete-modal>
    </b-modal>
    <b-modal id="modal-create-kiosk-site" title="Add a new Kiosk Site"  hide-footer>
      <CreateKioskSite @close="closeModals" @addToTable="updateKioskTable"/>
    </b-modal>
  </div>
</template>
<!--eslint-enable-->

<script>
import KioskSiteService from '@/services/KioskSiteService';
import DeleteModal from '@/components/modals/DeleteModal.vue';
import CreateKioskSite from '@/views/kiosk/viewKioskSites/CreateKioskSite.vue';
import helperService from '@/services/HelperService';

export default {
  name: 'KioskSite',
  components: {
    DeleteModal,
    CreateKioskSite,
  },
  props: {},
  data() {
    return {
      selectedSite: null,
      headers: [
        {
          key: 'mdm_name',
          label: 'MDM Name',
        },
        {
          key: 'urls',
          label: 'URLs',
        },
        {
          key: 'url_blacklist',
          label: 'URL Blacklist',
        },
        {
          key: 'resource_whitelist',
          label: 'Resource Whitelist',
        },
        {
          key: 'javascript',
          label: 'Javascript',
        },
        {
          key: 'css_rules',
          label: 'CSS Rules',
        },
        {
          key: 'actions',
          label: '',
          class: 'text-right',
        },
      ],
      sites: [],
      currentPage: 1,
      filters: {
        page: 0,
        page_size: 25,
        search: '',
      },
      total_count: 0,
    };
  },
  mounted() {
    this.setFilters();
    this.getSites();
  },
  methods: {
    updateKioskTable(site) {
      this.closeModals();
      this.sites.push(site)
    },
    getMoreSites(val) {
      this.filters.page = val - 1; // api index starts at 0, bootstrap starts at 1 lol
      this.getSites();
    },
    setFilters() {
      this.filters.secure = this.$route.query.secure || ''
      this.filters.encrypted = this.$route.query.encrypted || ''
    },
    async getSites() {
      try {
        const res = await KioskSiteService.getSites(this.filters)
        this.sites = res.data.data;
        this.filters.page = res.data.meta.page;
        this.filters.page_size = res.data.meta.page_size;
        this.total_count = res.data.meta.total_count;
      } catch (err) {
        helperService.showNotfyErr(this.$toast, err, 'Could not get kiosk sites, please refresh and try again')
      }
    },
    clearFilters() {
      this.filters = {
        page: 0,
        page_size: 25,
        search: '',
      };

      this.getMoreSites(1);
    },
    closeModals() {
      this.$bvModal.hide('modal-delete-kiosk-site');
      this.$bvModal.hide('modal-create-kiosk-site')
    },
    showDeleteModal(site, modalName) {
      this.selectedSite = site;
      this.$bvModal.show(modalName);
    },
    async deleteSite() {
      try {
        await KioskSiteService.deleteSite(this.selectedSite.id)

        this.$toast.success(`Deleted kiosk site ${this.selectedSite.mdm_name} Successfully`, {
          toastClassName: ['toast-std', 'success-toast'],
        });
        this.removeKioskSite()
      } catch (err) {
        helperService.showNotfyErr(this.$toast, err, 'Could not delete kiosk site, please refresh and try again')
      }
    },
    removeKioskSite() {
      this.closeModals();
      const indexOfDeletedSite = this.sites.findIndex(site => site.id === this.selectedSite.id);
      this.sites.splice(indexOfDeletedSite, 1)
    },
  },
};
</script>
